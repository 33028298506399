.video-container {
    position: relative;
    background-color: white;
    display: flex;
    width: 100%;
    height: 100vh;
    scroll-snap-align: start;
    scroll-snap-stop: always;
}

.video-thumb {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: opacity 400ms ease 0ms;
    scroll-snap-align: start;
    scroll-snap-stop: always;
}

.tiny {
    filter: blur(15px);
    transform: scale(1);
    transition: visibility 0ms ease 400ms;
}

.video__player {
    object-fit: fill;
    position: relative;
    width: 100%;
    height: 100%;
}

.icons__container {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-right: 6px;
    bottom: 0;
    right: 0;
    z-index: 99;
    margin-top: 30px;
}

.reply__icon {
    transform: scale(-1, 1);
}

.icons__box {
    margin-bottom: 10px;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, .2);
    border-radius: 10px;
    position: relative;
    z-index: 1;
    padding: 12px 10px;
    cursor: pointer;
}

.action__icon {
    position: relative;
    color: #ffffff;
    z-index: 99;
}

.video__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.question__box {
    position: absolute;
    bottom: 70px;
    left: 0;
    padding: 15px;
    z-index: 90;
    color: white;
}

.responder__box {
    position: absolute;
    bottom: 70px;
    right: 10px;
    padding: 15px;
    z-index: 100;
    color: white;
    cursor: pointer;
}

.responder {
    font-size: 21px !important;
    font-weight: bold !important;
}

.tags-wrapper {
    display: flex;
    flex-direction: row;
}

.tag {
    margin: 2px;
    font-size: 14px;
}

