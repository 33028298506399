html {
    scroll-snap-type: y mandatory;
    height: 100vh;
}


.app {
    /*height: calc(100vh - 100px);*/
}

header {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
}

.app__videos {
    position: relative;
}

.app__videos::-webkit-scrollbar {
    display: none;
}

.app__videos {
    height: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.no-content {
    height: calc(100vh - 100px);
    display: flex !important;
    justify-content: center;
    align-items: center;
}

@media (min-width: 768px) {
    html, body {
        max-width: 768px;
        width: 100%;
        height: 100vh;
        position: relative;
        margin: 0 auto !important;

    }

    body {
        background-color: #e9e7e7;
    }

    .app {
        max-width: 768px;
        width: 100%;
        background-color: #ffffff;
    }

    header {
        max-width: 768px;
        right: auto !important;
    }

    .footer__wrapper {
        max-width: 768px;
        left: auto !important;
    }
}
