.questions {
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
    padding-top: 35px;
    align-items: center;
    justify-content: space-around;
    background: #FFFFFF;
}

.app_questions-page {
    height: 100%;
    min-height: 100vh;
}

.question-card {
    margin: 15px;
    min-width: 290px;
    width: 90%;
    min-height: 412px;
    background: #FFFFFF;
    box-shadow: 0 4px 15px rgba(49, 43, 103, 0.2) !important;
    border-radius: 15px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.videoRecorderWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 45px;
    z-index: 10;
}

.questioner {
    font-size: 12px !important;
    font-family: 'Poppins', sans-serif !important;
    color: #545663 !important;
    line-height: 18px !important;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.box-actions-buttons {
    display: flex;
    justify-content: space-between;
}

.progress-bar {
    width: 80%;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
}

.uploading-text {
    text-align: center;
    margin-top: 0;
}

.box-actions-buttons button {
    color: #7165E3
}

.questioner .avatar-questioner {
    font-size: 12px;
    margin: 10px;
}

.question-text {
    text-align: center;
    font-style: normal;
    font-family: 'Poppins', sans-serif;
    font-weight: 500 !important;
    font-size: 32px !important;
    line-height: 125% !important;
}

.answer-button {
    background-color: #7165E3 !important;
    border-radius: 15px !important;
    padding: 15px !important;
    min-width: 142px !important;
}

.local-file-button {
    background-color: #ffffff !important;
    margin-top: 18px !important;
    color: #7165E3 !important;
    border-radius: 15px !important;
    padding: 10px !important;
    font-size: 12px !important;
    min-width: 100px !important;
}

.disabled-button_local {
    margin-top: 18px !important;
    background-color: #ffff !important;
    border-radius: 15px !important;
    border: 1px solid #7165E3 !important;
    padding: 10px !important;
    font-size: 12px !important;
    min-width: 100px !important;
}

.disabled-button {
    background-color: #ffff !important;
    border-radius: 15px !important;
    border: 1px solid #7165E3 !important;
    padding: 15px !important;
    min-width: 142px !important;
}

.question-card_answers-page {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
    margin-top: 0;
    padding-top: 20px;
    position: fixed;
    z-index: 1;
    max-width: 768px !important;
    min-height: 70px !important;
    box-shadow: 0 10px 5px -0 rgb(49 43 103 / 20%) !important;
    padding-bottom: 20px;
}

.questioner_answers-page {
    color: rgb(113, 101, 227) !important;
    font-size: 14px !important;
    cursor: pointer;
}

.answer-card {
    padding-top: 100px;
    justify-content: center;
    display: flex !important;
    flex-direction: column !important;
}

.amount_answers {
    font-size: 12px !important;
}
