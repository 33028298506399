.recent_wrapper {
    padding-top: 50px;
    padding-left: 8px;
    padding-right: 8px;
}

.recent_buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 5px;
}

.search-screen_videos {
    max-height: 18vh;
    min-height: 18vh;
    min-width: 20vh;
    max-width: 20vh;
    object-fit: fill;
    border-radius: 12px;
    margin: 1px;
}

.recent-icon {
    color: #ffffff;
    padding: 7px;
    border-radius: 10px;
    background-color: #7165E3;
    font-size: 12px;
    text-align: center;
    min-width: 30px;
}

.videos-wrapper {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    width: 100%;
}

.recent-button {
    border: none;
    color: #7165E3;
    background-color: inherit;
    font-size: 14px;
    cursor: pointer;
}

.input-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.search-input {
    width: 95%;
}

.search-input fieldset {
    border-radius: 20px !important;
}

.all-answers {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.video-box {
    max-width: 200px;
}

@media (min-width: 768px) {

    .recent_buttons {
        margin: 0 auto;
    }
}
