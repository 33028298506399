.video_tag-page {
    max-width: 300px;
    max-height: 300px;
    margin: 0 auto;
}

.video_tag-page video {
    width: 100%;
    border-radius: 20px;
}

.tags-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
}

.tag-page_wrapper {
    height: calc(100vh - 100px);
}
