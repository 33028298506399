.start-record_wrapper {
    width: 48px;
    height: 48px;
    background-color: inherit;
    border: 1px white solid;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 125%;
    color: #ffffff;
}

.start-record_button {
    width: 240px;
    height: 56px;
    background-color: #BC2030;
    border: none;
    border-radius: 100px;
    outline: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 125%;
    color: #ffffff;
}

.stop-record_button {
    width: 240px;
    height: 56px;
    background-color: #C04955;
    border: none;
    font-size: 16px;
    line-height: 125%;
    color: #ffffff;
    border-radius: 100px;
    outline: none;
    padding: 0;
    margin: 0;
}

.uploading_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.recorder-tags-wrapper {
    display: flex;
    margin-top: 10px;
    justify-content: center;
}

.new-record_button {
    outline: none;
    background-color: #8f0f0f;
    border: none;
    color: #ffffff;
    padding: 5px;
    border-radius: 8px;
    font-size: 12px;
    margin: 2px;
}

.send-record_button {
    outline: none;
    background-color: rgb(25, 138, 60);
    border: none;
    color: #ffffff;
    padding: 5px;
    border-radius: 8px;
    font-size: 12px;
    margin: 2px;
}

.tags-list {
    padding-top: 100px !important;
}

.tag-button {
    outline: none;
    border: none;
    color: #ffffff;
    padding: 7px;
    background: #7165E3;
    border-radius: 15px;
    font-size: 16px;
    margin: 2px;
    width: 240px;
    height: 56px;
    cursor: pointer;
}
