.profile-container {
    width: 100%;
    max-width: 100% !important;
}

.profile-card {
    position: relative;
    display: flex;
    margin-top: 50px;
    min-height: 80vh;
    justify-content: center;
    flex-direction: column;
    max-width: 100% !important;
    box-shadow: 0 -1px 5px -1px rgba(34, 60, 80, 0.2);
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.avatar__box {
    display: flex;
    justify-content: center;
}

.avatar {
    top: -35px;
    padding: 1rem;
    border: 3px #e9e7e7 solid;
    font-size: 1rem !important;
}

.followers-box {
    padding: 20px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}

.followers-tab b {
    margin: 5px;
    font-size: 20px;
}

.followers-tab span {
    font-size: 15px;
    color: darkgrey;
}

.ask__button-box {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 50px;
    border-bottom: 1px solid #e9e7e7;
}

.ask__button-box button {
    border-radius: 12px;
}

.tabs-box {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    padding: 40px;
}

.action-tab {
    color: darkgrey;
}

.active {
    color: black;
}

.answers-questions_box {
    padding-bottom: 100px;
    background-color: #FFFFFF;
}

.profile-answer_wrapper {
    background-color: rgba(0, 0, 0, 0.11);
    max-height: 18vh;
    min-height: 18vh;
}
