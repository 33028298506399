.footer__wrapper {
    background: #ffffff;
    height: 65px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 -1px 5px -1px rgba(34, 60, 80, 0.2);
}

.footer_desc {
    font-size: .7rem !important;
}

.footer_icon_box {
    color: lightgrey;
    min-width: 2rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    flex-direction: column;
}

.activeTab-footer {
    color: rebeccapurple
}

.activeTab-footer a {
    color: rebeccapurple
}

.center-icon .activeTab-footer {
    border-color: rebeccapurple;
}

.footer_icon_box a {
    color: inherit;
    max-height: 32px;
}

.center-icon {
    border: 2px solid;
}

.center-icon a {
    max-height: 32px;
}

.action__icon-footer {
    font-size: 2rem !important;
}

@media (max-width: 732px) {
    .footer__wrapper {
        padding-bottom: 30px;
        height: 85px;
    }
}
